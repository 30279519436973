import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import Main from "./components/Main/Main";
import Login from "./pages/Login";
import Register from "./pages/Register";
import HrDash from "./pages/Hr/HrDash";
import HrLayout from "./pages/Hr/HrLayout";
import RegisterStaff from "./pages/Hr/RegisterStaff";
import HrStaff from "./pages/Hr/HrStaff";
import SendEmails from "./pages/Hr/SendEmails";
import StaffPreview from "./pages/Hr/StaffPreview";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import VerifyEmail from "./pages/VerifyEmail";
import ProtectedRoute from "./components/ProtectedRoute";
import HrLogin from "./pages/Hr/HrLogin";
import CompanyProtectedRoute from "./components/CompanyProtectedRoute";
import AdminDash from "./pages/Admin/AdminDash";
import AdminLayout from "./pages/Admin/components/AdminLayout";
import AdminUsers from "./pages/Admin/AdminUsers";
import AdminCompanies from "./pages/Admin/AdminCompanies";
import AdminAddUser from "./pages/Admin/AdminAddUser";
import AdminAddCompany from "./pages/Admin/AdminAddCompany";
import AdminUserSubs from "./pages/Admin/AdminUserSubs";
import AdminCounsellors from "./pages/Admin/AdminCounsellors";
import AdminAddCounsellor from "./pages/Admin/AdminAddCounsellor";
import AdminAddPlan from "./pages/Admin/AdminAddPlan";
import AdminPlans from "./pages/Admin/AdminPlans";


function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path='/' element={
          <Layout>
            <Main />
          </Layout
        } /> */}
        <Route element={<ProtectedRoute />}>
          <Route path='/' element={
            <Layout>
              <Main />
            </Layout>
          } />
        </Route>
        <Route path='/login' element={<Login />} />
        <Route path='/register' element={<Register />} />
        <Route path='/verify-email' element={<VerifyEmail />} />
        <Route path='/hr/login' element={<HrLogin />} />

        <Route element={<CompanyProtectedRoute />}>
          <Route
            path="/hr/*"
            element={

              <Routes>
                
                <Route path='/' element={
                  <HrLayout>
                    <HrDash />
                  </HrLayout>
                } />
                <Route path='/register-staff' element={
                  <HrLayout>
                    <RegisterStaff />
                  </HrLayout>
                } />
                <Route path='/all-staff' element={
                  <HrLayout>
                    <HrStaff />
                  </HrLayout>
                } />
                <Route path='/send-emails' element={
                  <HrLayout>
                    <SendEmails />
                  </HrLayout>
                } />
                <Route path='/staff-preview' element={
                  <HrLayout>
                    <StaffPreview />
                  </HrLayout>
                } />


              </Routes>
              // </Layout>
            }
          />
        </Route>
        <Route
            path="/admin/*"
            element={

              <Routes>
                
                <Route path='/' element={
                  <AdminLayout>
                    <AdminDash />
                  </AdminLayout>
                } />
                <Route path='/users' element={
                  <AdminLayout>
                    <AdminUsers />
                  </AdminLayout>
                } />
                <Route path='/users-active' element={
                  <AdminLayout>
                    <AdminUserSubs />
                  </AdminLayout>
                } />
                <Route path='/users-expired' element={
                  <AdminLayout>
                    <AdminUsers />
                  </AdminLayout>
                } />
                <Route path='/create-user' element={
                  <AdminLayout>
                    <AdminAddUser />
                  </AdminLayout>
                } />
                <Route path='/corporates' element={
                  <AdminLayout>
                    <AdminCompanies />
                  </AdminLayout>
                } />
                <Route path='/add-corporate' element={
                  <AdminLayout>
                    <AdminAddCompany />
                  </AdminLayout>
                } />
                <Route path='/counsellors' element={
                  <AdminLayout>
                    <AdminCounsellors />
                  </AdminLayout>
                } />
                <Route path='/add-counsellor' element={
                  <AdminLayout>
                    <AdminAddCounsellor />
                  </AdminLayout>
                } />
                <Route path='/plans' element={
                  <AdminLayout>
                    <AdminPlans />
                  </AdminLayout>
                } />
                <Route path='/create-plan' element={
                  <AdminLayout>
                    <AdminAddPlan />
                  </AdminLayout>
                } />
              </Routes>
              // </Layout>
            }
          />
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
