import React from 'react';

const OtpModal = ({ otp, setOtp, handleOtpSubmit, otpCountdown, handleRegenerateOtp }) => {
    return (
        <div className='fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center'>
            <div className='bg-white p-6 rounded-md shadow-md'>
                <h2 className='text-xl mb-4'>Enter OTP</h2>
                <input
                    type="text"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    className='w-full p-2 mb-4 border border-gray-300 rounded-md'
                    placeholder='Enter OTP'
                />
                <button onClick={handleOtpSubmit} className='bg-green-400 text-white px-4 py-2 w-full rounded-md mb-3'>
                    Verify OTP
                </button>
                <p className='text-sm text-gray-500'>Didn't receive the OTP? {otpCountdown > 0 ? `Resend in ${otpCountdown}s` : <span className='underline cursor-pointer text-blue-500' onClick={handleRegenerateOtp}>Resend OTP</span>}</p>
            </div>
        </div>
    );
}

export default OtpModal;
