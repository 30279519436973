/* eslint-disable react/prop-types */
// import React from 'react'

import { useState } from "react";
import Settings from "./Modals/Settings/Settings";
import Sidebar from "./Sidebar/Sidebar"

const Layout = ({ children }) => {
    const [settingsModalVisible, setSettingsModalVisible] = useState(false);

    const showSettingsModal = () => {
        setSettingsModalVisible(true);
    };

    const closeSettingsModal = () => {
        setSettingsModalVisible(false);
    };
    return (
        <>
            <div id="root">
                <Sidebar onSettingsClick={showSettingsModal} />
                <div className="relative main flex-grow">
                    {/* <Settings show={settingsModalVisible} onClose={closeSettingsModal} /> */}
                    {children}
                </div>
                <Settings show={settingsModalVisible} onClose={closeSettingsModal} />
            </div>
        </>

    )
}

export default Layout