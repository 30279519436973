
import React from 'react'

const PersonalSettings = () => {
  return (
    <div>
      <div>
        <div className='flex w-full justify-between p-4 border-b'>
          <h1>Text Color</h1>
          <select name="" id="">
            <option value="">Black</option>
            <option value="">White</option>
            <option value="">Pink</option>
            <option value="">Purple</option>
          </select>

        </div>
        <div className='flex w-full justify-between items-center p-4 border-b'>
          <h1>Background Color</h1>
          <select name="" id="">
            <option value="">Black</option>
            <option value="">White</option>
            <option value="">Pink</option>
            <option value="">Purple</option>
          </select>
        </div>
        {/* <div className='flex w-full justify-between items-center p-4 border-b'>
          <h1>Delete all chats</h1>
          <button className='py-2 px-4 bg-red-500 text-white rounded-md'>
            Delete Chats
          </button>
        </div> */}
      </div>
    </div>
  )
}

export default PersonalSettings