import React, { useState, useEffect } from 'react';
import { FcGoogle } from "react-icons/fc";
import { TypeAnimation } from 'react-type-animation';
import Navbar from '../components/Navbar';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser, verifyOtp, resetOtpCountdown, decrementOtpCountdown } from '../redux/authSlice';
import OtpModal from '../components/OtpModal';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'; // For redirection

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { otpRequired, otpCountdown, loading, error, otpStatus, token } = useSelector((state) => state.auth);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [otp, setOtp] = useState('');
    const [otpModalVisible, setOtpModalVisible] = useState(false);

    useEffect(() => {
        if (otpStatus === 'verified') {
            toast.success('OTP verification complete, login successful');
            // Redirect to home page
            navigate('/');
        }
    }, [otpStatus, navigate]);

    useEffect(() => {
        if (otpRequired) {
            setOtpModalVisible(true);
        }
    }, [otpRequired]);

    useEffect(() => {
        if (otpModalVisible) {
            const timer = setInterval(() => {
                dispatch(decrementOtpCountdown());
            }, 1000);
            return () => clearInterval(timer);
        }
    }, [otpModalVisible, dispatch]);

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(loginUser({ email, password }));
        // toast.success('Login successful');
        navigate('/'); // Redirect to home page
    };

    const handleOtpSubmit = () => {
        dispatch(verifyOtp({ email, otp }));
    };

    const handleRegenerateOtp = () => {
        // Call your API to regenerate OTP if needed
        dispatch(resetOtpCountdown());
    };

    useEffect(() => {
        // Redirect if login is successful without OTP
        if (!otpRequired && !loading && !error) {
            const token = localStorage.getItem('token'); // or any other method you're using to store the token
            if (token) {
                toast.success('Login successful');
                navigate('/'); // Redirect to home page
            }
        }
    }, [otpRequired, loading, error, navigate]);

    return (
        <div className='bg-green-200 h-screen w-screen'>
            <Navbar />
            <div className='flex flex-col justify-center items-center h-[80vh]'>
                <div className='w-full mb-4 flex flex-col justify-center items-center'>
                    <TypeAnimation
                        preRenderFirstString={true}
                        sequence={[
                            500,
                            'Clear Your Anxiety',
                            1000,
                            'Get Professional Help',
                            1000,
                            'Get Over Addictions',
                            1000,
                            'Be Better',
                            500,
                        ]}
                        speed={50}
                        className='text-center text-5xl font-medium'
                        style={{ fontSize: '3em' }}
                        repeat={Infinity}
                    />
                    <h1 className='text-center text-5xl'>With Uzima AI</h1>
                </div>
                <div>
                    <form onSubmit={handleSubmit} className='border border-green-100 w-[450px] p-[30px] rounded-xl shadow-md'>
                        <h1 className='text-center mb-3'>Start using Uzima for yourself or your team.</h1>
                        <div className='flex flex-col justify-center items-center'>
                            <button type='button' className='flex gap-2 items-center justify-center mb-3 px-4 py-2 bg-white w-full rounded-md'>
                                <FcGoogle />
                                Continue with Google
                            </button>
                            <p className='font-semibold mb-3'>OR</p>
                            <input
                                type="email"
                                className='w-full p-2 rounded-md outline-none mb-3'
                                placeholder='name@company.com'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <input
                                type="password"
                                className='w-full p-2 rounded-md outline-none mb-3'
                                placeholder='*********'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <button type='submit' className='bg-green-400 text-white px-4 py-2 w-full rounded-md mb-3'>
                                {loading ? 'Loading...' : 'Login'}
                            </button>
                            {error && <p className='text-red-500'>{error}</p>}
                            <div className='mb-6 flex w-full justify-between items-center'>
                                <a href="/forgot" className='text-sm text-start underline text-blue-500'>Forgot Password?</a>
                                <p className='text-sm text-gray-500'>Don't have an account? <a href='/register' className='underline text-green-500'>Register Here</a></p>
                            </div>
                            <p className='text-sm text-gray-500'>By continuing, you agree to Uzima's <span className='underline'>Consumer terms</span> and <span className='underline'>usage policy</span>, and acknowledge our <span className='underline'>privacy policy.</span></p>
                        </div>
                    </form>
                </div>
            </div>
            {otpModalVisible && (
                <OtpModal
                    otp={otp}
                    setOtp={setOtp}
                    handleOtpSubmit={handleOtpSubmit}
                    otpCountdown={otpCountdown}
                    handleRegenerateOtp={handleRegenerateOtp}
                />
            )}
        </div>
    );
};

export default Login;
