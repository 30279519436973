
import React from 'react'

const SecuritySettings = () => {
  return (
    <div>
      <div>
        <div className='flex w-full justify-between items-center gap-5 p-4 border-b'>
          <div>
            <h1>Multi-factor authentication</h1>
            <p className='text-sm text-gray-500'>Require an extra security challenge when logging in. If you are unable to pass this challenge, you will have the option to recover your account via email.</p>
          </div>
          <button className='py-2 px-4 bg-green-500 text-white rounded-md'>
            Enable
          </button>
        </div>
        <div className='flex w-full justify-between items-center gap-5 p-4 border-b'>
          <div>
            <h1>Log out of all devices</h1>
            <p className='text-sm text-gray-500'>
              Log out of all active sessions across all devices, including your current session. It may take up to 30 minutes for other devices to be logged out.
            </p>
          </div>
          <button className='py-2 px-4 bg-green-500 h-[40px] flex items-center text-white rounded-md'>
            Logout
          </button> 
        </div>
        
      </div>
    </div>
  )
}

export default SecuritySettings