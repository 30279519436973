import React, { useState } from 'react'
import './Main.css'
import { assets } from '../../assets/assets'
import { useContext } from 'react'
import { Context } from '../../context/Context'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { logout } from '../../redux/authSlice';


const Main = () => {

    const { onSent, recentPrompt, showResult, loading, resultData, setInput, input } = useContext(Context);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const user = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const handleLogout = () => {
        dispatch(logout());
        navigate('/login');
    };

    // const closeDropdown = () => {
    //     setDropdownVisible(false);
    // };

    return (
        <div>
            <div className="nav">
                <p>Uzima</p>
                <img
                    src={assets.user_icon}
                    alt="User Icon"
                    onClick={toggleDropdown}
                    className="cursor-pointer"
                />
                {dropdownVisible && <div className="dropdown-menu top-20 absolute right-4 mt-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg">
                    <ul>
                        <li className="px-4 py-2 hover:bg-gray-100 text-[18px] cursor-pointer" >
                            <a href="/profile">Profile</a>
                        </li>
                        <li className="px-4 py-2 hover:bg-gray-100 text-[18px] cursor-pointer">
                            <a href="/settings">Settings</a>
                        </li>
                        <li onClick={handleLogout} className="px-4 py-2 hover:bg-gray-100 text-[18px] cursor-pointer" >
                            Logout
                        </li>
                    </ul>
                </div> }
            </div>
            <div className="main-container">
                {!showResult ? <>
                    <div className="greet">
                        <p><span>Hello, {user?.firstName}</span></p>
                        <p>How are you feeling today?</p>
                    </div>
                    {/* <div className='cards'>
                        <div className="card">
                            <p>Suggest beautiful places to see on an upcoming road trip</p>
                            <img src={assets.compass_icon} alt="" />
                        </div>
                        <div className="card">
                            <p>Briefly summarize this concept: urban planning</p>
                            <img src={assets.bulb_icon} alt="" />
                        </div>
                        <div className="card">
                            <p>Brainstorm team bonding activities for our work retreat</p>
                            <img src={assets.message_icon} alt="" />
                        </div>
                        <div className="card">
                            <p>Improve the readability of the following code</p>
                            <img src={assets.code_icon} alt="" />
                        </div>
                    </div> */}

                </> :

                    <div className='result'>
                        <div className="result-title">
                            <img src={assets.user_icon} alt="" />
                            <p>{recentPrompt}</p>
                        </div>
                        <div className="result-data">
                            <img src={assets.gemini_icon} alt="" />
                            {loading ? <div className='loader'>
                                <hr />
                                <hr />
                                <hr />
                            </div> : <p dangerouslySetInnerHTML={{ __html: resultData }}></p>
                            }
                        </div>
                    </div>
                }

                <div className="main-bottom">
                    <div className="search-box">
                        <input onChange={(e) => setInput(e.target.value)} value={input} type="text" placeholder='Enter a prompt here' />
                        <div>
                            <img src={assets.gallery_icon} alt="" />
                            <img src={assets.mic_icon} alt="" />
                            {input ? <img onClick={() => onSent()} src={assets.send_icon} alt="" /> : null}
                        </div>
                    </div>
                    <p className='bottom-info'>
                        Uzima may display inaccurate info, including about people, so double-check its responses. Your privacy and Uzima Apps matters.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Main