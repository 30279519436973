import React from 'react'
import { LiaTelegramPlane } from "react-icons/lia";

const Navbar = () => {
  return (
    <div className='w-full h-[70px] border-b border-green-100 flex justify-between px-[40px] items-center'>
        <div>
            <img src="" alt="" />
            <h1 className='font-semibold text-3xl'>Uzima</h1>
        </div>
        <div>
            <a href='/' className='border border-green-100 rounded-md px-4 py-2 flex items-center gap-2 hover:bg-green-400 hover:text-white transition-all 0.5s'>
                Visit Our Website
                <LiaTelegramPlane />
            </a>
        </div>
    </div>
  )
}

export default Navbar