/* eslint-disable no-unused-vars */
import React from 'react'

const GeneralSettings = () => {
  return (
    <div>
      <div>
        <div className='flex w-full justify-between p-4 border-b'>
          <h1>Theme</h1>
          <select name="" id="">
            <option value="">System</option>
            <option value="">Light</option>
            <option value="">Dark</option>
          </select>

        </div>
        <div className='flex w-full justify-between items-center p-4 border-b'>
          <h1>Language</h1>
          <select name="" id="">
            <option value="">Default(English)</option>
            <option value="">Spanish</option>
            <option value="">German</option>
          </select>
        </div>
        <div className='flex w-full justify-between items-center p-4 border-b'>
          <h1>Delete all chats</h1>
          <button className='py-2 px-4 bg-red-500 text-white rounded-md'>
            Delete Chats
          </button>
        </div>
      </div>
    </div>
  )
}

export default GeneralSettings