import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TypeAnimation } from 'react-type-animation';
import Navbar from '../components/Navbar';
import { registerUser } from '../redux/authSlice'; // Adjust the path as needed
import { toast } from 'react-toastify';
import { Navigate, useNavigate } from 'react-router-dom';

const Register = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate()

    const dispatch = useDispatch();
    const { loading, error } = useSelector((state) => state.auth);

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(registerUser({ firstName, lastName, email, password }))
            .unwrap()
            .then(() => {
                toast.success('Registration successful. Kindly verify your email before login.');
                navigate('/login');
                // Optionally redirect to login or home page
            })
            .catch((err) => {
                toast.error(`Registration failed: ${err.message}`);
            });
    };

    return (
        <div className='bg-green-200 h-screen w-screen'>
            <Navbar />
            <div className='' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                <div className='w-full mb-4' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <TypeAnimation
                        preRenderFirstString={true}
                        sequence={[
                            500,
                            'Clear Your Anxiety',
                            1000,
                            'Get Professional Help',
                            1000,
                            'Get Over Addictions',
                            1000,
                            'Be Better',
                            500,
                        ]}
                        speed={50}
                        className='text-center text-5xl font-medium'
                        style={{ fontSize: '3em' }}
                        repeat={Infinity}
                    />
                    <h1 className='text-center text-5xl'>With Uzima AI</h1>
                </div>
                <div>
                    <form onSubmit={handleSubmit} className='border border-green-100 w-[450px] p-[30px] rounded-xl shadow-md'>
                        <h1 className='text-center mb-3'>Start using Uzima for yourself or your team.</h1>
                        <div className='' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <div className='grid grid-cols-2 gap-2'>
                                <input
                                    type="text"
                                    className='w-full p-2 rounded-md outline-none mb-3'
                                    placeholder='First Name'
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                />
                                <input
                                    type="text"
                                    className='w-full p-2 rounded-md outline-none mb-3'
                                    placeholder='Last Name'
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                />
                            </div>
                            <input
                                type="email"
                                className='w-full p-2 rounded-md outline-none mb-3'
                                placeholder='name@company.com'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <input
                                type="password"
                                className='w-full p-2 rounded-md outline-none mb-3'
                                placeholder='*********'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <button className='bg-green-400 text-white px-4 py-2 w-full rounded-md mb-3' type='submit' disabled={loading}>
                                {loading ? 'Creating Account...' : 'Create Account'}
                            </button>
                            {error && <p className='text-red-500'>{error}</p>}
                            <div className='mb-6 flex w-full justify-between items-center'>
                                <a href="/forgot" className='text-sm text-start underline text-blue-500'>Forgot Password?</a>
                                <p className='text-sm text-gray-500'>Already have an account? <a href='/login' className='underline text-green-500'>Login Here</a></p>
                            </div>
                            <p className='text-sm text-gray-500'>By continuing, you agree to Uzima's <span className='underline'>Consumer terms</span> and <span className='underline'>usage policy</span>, and acknowledge our <span className='underline'>privacy policy.</span></p>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Register;
