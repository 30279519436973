import axios from 'axios';

const apiKey = 'sk-proj-Izod8UCzyksWUCfJELULT3BlbkFJSHEAzCL66Y40cqhbn7Bw'; // Replace with your actual OpenAI API key
const apiEndpoint = 'https://chatgpt.com/g/g-VFFsSMgu9-uzima-ai'; // Use the provided custom GPT link

const run = async (prompt) => {
  try {
    const response = await axios.post(
      apiEndpoint,
      {
        prompt: prompt,
        max_tokens: 2048,
        temperature: 1,
        top_p: 0.95,
        n: 1,
        stream: false,
      },
      {
        headers: {
          Authorization: `Bearer ${apiKey}`,
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.data.choices && response.data.choices.length > 0) {
      const text = response.data.choices[0].text.trim();
      console.log(text);
      return text;
    } else {
      console.error('No response from OpenAI');
      return 'No response from OpenAI';
    }
  } catch (error) {
    console.error('Error during OpenAI request:', error);
    return 'Error during OpenAI request';
  }
};

export default run;

