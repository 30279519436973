/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import GeneralSettings from './GeneralSettings';
import PersonalSettings from './PersonalSettings';
import SecuritySettings from './SecuritySettings';

const Settings = ({ show, onClose }) => {
  const [activeTab, setActiveTab] = useState('profile');

  if (!show) {
    return null;
  }

  const renderContent = () => {
    switch (activeTab) {
      case 'security':
        return <SecuritySettings />;
      case 'personalisation':
        return <PersonalSettings />;
      case 'general':
        return <GeneralSettings />;
      default:
        return <GeneralSettings />;
    }
  };

  return (
    <div className='fixed inset-0 bg-gray-600 bg-opacity-60 flex justify-center items-center z-50'>
      <div className='bg-white w-96 max-w-full shadow-md relative p-6 rounded-lg'>
        <button onClick={onClose} className="absolute top-2 right-2 text-gray-600 hover:text-gray-800">
          &times;
        </button>
        <h1 className="text-xl font-semibold mb-4">Settings</h1>
        <div className="tabs mb-4 border-b pb-2">
          <button 
            className={`px-4 py-2 ${activeTab === 'general' ? 'bg-gray-400' : 'bg-gray-200'} rounded`} 
            onClick={() => setActiveTab('general')}
          >
            General
          </button>
          <button 
            className={`ml-2 px-4 py-2 ${activeTab === 'personalisation' ? 'bg-gray-300' : 'bg-gray-200'} rounded`} 
            onClick={() => setActiveTab('personalisation')}
          >
            Personalisation
          </button>
          <button 
            className={`ml-2 px-4 py-2 ${activeTab === 'security' ? 'bg-gray-300' : 'bg-gray-200'} rounded`} 
            onClick={() => setActiveTab('security')}
          >
            Security
          </button>
        </div>
        {renderContent()}
      </div>
    </div>
  )
}

export default Settings